<template>
  <div class="scrollbar-hide">
    <main class="container md:pb-12 scrollbar-hide">
      <BootcampNotice 
      v-if="showBootcampNotice == true && (user?.batch?.can_access == 1) && (user?.hasPaidBootcamp != true) && site_settings.bootcamp_payment_active == 1 && user.batch?.course.bootcamp_available == 1 && user.cohort >= 7"
      @closeBootcampNotice="setBootcampNotice(false)" />
      <DashboardAnnouncements />
      <!-- Start Section 2 -- Continue Learning PAth Card  -->
      <LiveClass />
      <LearningPath />
      <!-- End Section 2 -- Continue Learning PAth Card  -->
      <!-- Start Navigation Buttons -->
      <NavigationButtons />
      <!-- End Navigation Buttons -->
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import FeedbackModal from "./feedback/FeedBackModal.vue";
import GreetingCard from "./IndexComponents/GreetingCardComponent.vue";
import LearningPath from "./IndexComponents/LearningPathComponent.vue";
import NavigationButtons from "./IndexComponents/NavigationButtonsComponent.vue";
import RecommendedLessons from "./IndexComponents/RecommendedLessonsComponent.vue";
import ReapplyModal from "@/components/lms/ReapplyModal.vue";
import BootcampNotice from "@/components/lms/BootcampNotice.vue";
import DashboardAnnouncements from "@/components/lms/DashboardAnnouncements.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import LiveClass from "./liveclasses/LiveClassNotice.vue";

export default {
  data() {
    return {
      //
    };
  },
  methods: {
    ...mapActions({
      setEnrollPrompt: "lms/setEnrollPrompt",
      setBootcampNotice: "lms/setBootcampNotice",
      setTakenQuiz: "lms/setTakenQuiz",
    }),
    convertTime(time) {
      const now = new Date();
      var date = new Date(time);
      date.setDate(date.getDate() + 1);
      const update_at = new Date(date);
      return update_at - now;
    },
    checkNewCohort() {
      if (this.showEnrollPrompt == false) {
        return;      
      }      
      if (this.user?.batch_id > 5) {
        this.setEnrollPrompt(false);
        return true;
      } else {
        this.setEnrollPrompt(true);
        return false;
      }
    },
  },
  components: {
    GreetingCard,
    LearningPath,
    NavigationButtons,
    RecommendedLessons,
    FeedbackModal,
    VueCountdown,
    ReapplyModal,
    BootcampNotice,
    DashboardAnnouncements,
    LiveClass,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      lessons: "lms/lessons",
      userCourse: "auth/userCourse",
      baseline: "auth/baseline",
      site_settings: "auth/settings",
      takenQuiz: "lms/takenQuiz",
      showEnrollPrompt: "lms/showEnrollPrompt",
      showBootcampNotice: "lms/showBootcampNotice",
    }),
  },
  mounted() {
    this.setTakenQuiz()
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
</style>
