<template>
  <div class="w-full p-2">
    <div class="flex items-center justify-start grow">
      <div class="relative max-w-full z-10 bg-[#69ADF8] rounded-full"  v-if="user?.batch?.learning_type == 'instructor'">
        <img :src="userRank.image" :alt="userRank.star" class="w-12" />
        <div
          class="bg-[#044995] rounded py-1 px-2 absolute -translate-x-1/2 left-1/2 -bottom-6 max-w-fit font-body text-[12px] font-bold text-white flex items-center justify-center"
        >
          <i class="fa fa-star text-[#FFCE31] text-[9px] mr-1"></i>
          {{ userRank.star }}
        </div>
      </div>
      <div class="w-full grow relative">
        <div class="bg-[#CED0D3] w-full h-3 rounded-full overflow-clip -ml-1">
          <div
            class="bg-[#52A350] rounded-full h-full"
            :style="`width: ${
              userStreak?.streak < 1 ? 0 : userStreak?.streak
            }%`"
          ></div>
        </div>
        <div class="absolute -bottom-6 left-4"  v-if="user?.batch?.learning_type == 'instructor'">
          <star-rating
            :read-only="true"
            :rating="userRank.star"
            :star-size="16"
            :padding="12"
            :max-rating="4"
            :show-rating="false"
            active-color="#FFCE31"
            inactive-color="#E6EAEE"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import StarRating from "vue-star-rating";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

const store = useStore();

const user = computed(() => {
  return store.state.auth.user;
});

/**
 * user streak data
 * @return int
 */
const userStreak = computed(() => {
  return store.state.lms.userStreak;
});

/**
 * Rank data
 * @return { Object }
 */
const userRank = computed(() => {
  // 0 star- Rookie -0-10
  // 1 star- Captain -10-30
  // 2 stars- Colonel -30-50
  // 3 stars- General -50-70
  // 4 stars- Field Marshal -70-100

  if (userStreak.value.streak >= 0 && userStreak.value.streak <= 10) {
    return {
      star: 0,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
    };
  }
  if (userStreak.value.streak > 10 && userStreak.value.streak <= 30) {
    return {
      star: 1,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Captain.png",
    };
  }
  if (userStreak.value.streak > 30 && userStreak.value.streak <= 50) {
    return {
      star: 2,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Colonel.png",
    };
  }
  if (userStreak.value.streak > 50 && userStreak.value.streak <= 70) {
    return {
      star: 3,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/General.png",
    };
  }
  if (userStreak.value.streak > 70) {
    return {
      star: 4,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Marshal.png",
    };
  }
  return {
    star: 0,
    image:
      "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
  };
});
</script>

<style></style>
