<template>
  <Popover class="relative">
    <PopoverButton as="div">
      <div
        class="text-sm flex items-center text-[#9E9E9E] font-medium focus:border-none relative cursor-pointer"
      >
        <span
          class="iconify text-[#999999] inline text-xl"
          :class="notifications?.length < 1 ? 'animate-pulse ease-linear' : ''"
          data-icon="clarity:notification-solid"
        ></span>
        <button
          v-if="notifications?.length > 0"
          class="absolute top-1/2 -translate-y-1/2 left-1/2 bg-primary-green p-1 rounded-full flex items-center justify-center w-5 h-5 font-body-new text-[10px] text-white border-2 border-white"
        >
          {{ notifications?.length ?? "0" }}
        </button>
      </div>
    </PopoverButton>
    <PopoverPanel class="absolute z-10">
      <div
        class="dark:bg-[#2E2E2E] shadow-2xl bg-white rounded-lg drop-shadow-xl pt-4 md:pt-8 px-4 md:px-6 absolute top-0 right-0 w-[70vw] md:w-[55vw] lg:w-[32rem] font-body text-caption"
      >
        <h3 class="dark:text-[#FDFDFD] text-[#646D64] font-semibold text-lg">
          Notification
        </h3>
        <!-- #FDFDFD -->
        <div
          v-if="notifications && notifications.length > 0"
          class="dark:text-[#F4F4F4] text-[#0C180C] my-8 container px-0 max-h-80 overflow-y-scroll"
        >
          <ul>
            <template
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div
                class="flex items-start justify-start pb-4 mb-4 border-b-[0.1px] border-[#D9D9D9] cursor-pointer"
                @click="
                  markNotificationRead(notification.id, notification.data)
                "
              >
                <i
                  class="fa-solid fa-circle text-primary-green text-[7px] mt-2 mr-2"
                ></i>
                <p>
                  {{ notification.data.message }}
                </p>
              </div>
            </template>
          </ul>
        </div>
        <p
          class="dark:text-[#F4F4F4] text-[#0C180C] my-4 cursor-pointer"
          v-if="notifications && notifications.length == 0"
        >
          You do not have any unread notifications
        </p>
        <p
          class="dark:text-[#F4F4F4] text-[#0C180C] my-4 cursor-pointer text-center"
          v-if="!notifications"
        >
          <i class="fa-solid fa-spinner fa-spin fa-2x"></i>
        </p>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  components: { Popover, PopoverButton, PopoverPanel },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  data() {
    return {
      notifications: null,
    };
  },
  watch: {
    $route() {
      // this.fetchNotifications();
    },
  },
  methods: {
    async fetchNotifications() {
      try {
        const { data } = await axios.get("auth/notifications");
        this.notifications = data.payload;
      } catch (error) {
        console.log(error);
        this.notifications = [];
      }
    },
    async markNotificationRead(id, action) {
      try {
        const payload = {
          id: id,
        };
        const { data } = await axios.post("auth/notifications/read", payload);
        this.notifications = data.payload;
        if (action.action == "goodies") {
          return;
        }
        if (action.action == "badge") {
          this.$router.push({ name: "BadgesPage" });
          return;
        }
        if (action.action == "comment" && action.post) {
          this.$router.push({
            name: "ForumPage",
            params: { post: action.post },
          });
          return;
        }
        return;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    // this.fetchNotifications();
  },
};
</script>

<style></style>
