<template>
  <BootcampModal 
    v-if="showBootcampModal == true"
    @closeBootcampNotice="showBootcampModal = false"
  />
  <!-- Start Navigation Buttons -->
  <div class="p-0">
    <div class="p-0 my-8 mb-12 font-body">
      <h2
        class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
      >
        Explore LMS
      </h2>
      <nav
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 justify-between items-start gap-4 md:gap-x-8 md:gap-y-8 dark:text-[#F4F4F4] text-[#0C180C] w-full py-4 transition mt-4 relative"
      >
        <router-link
          :to="{ name: 'AssessmentHistory' }"
          class="explore_lms_links dark:text-[#9774D1] text-[#8B46FF]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="ic:round-assessment"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            View your
          </p>
          <p class="font-bold text-sm">assessment history</p>
        </router-link>
        <template v-if="user?.cohort < 8">
          <router-link
            :to="{ name: 'ChoosePayment' }"
            class="explore_lms_links dark:text-[#D6895B] text-[#FB8036]"
          >
            <span
              class="iconify inline-block font-bold text-2xl mb-2"
              data-icon="fluent:payment-32-filled"
            ></span>
            <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
              Make payment for
            </p>
            <p class="font-bold text-sm">certificate</p>

          </router-link>
        </template>
        <template v-else-if="user?.cohort >= 8 && user?.batch?.learning_type == 'instructor' && user?.access_weeks < 6">
          <router-link
            :to="{ name: 'ApplicationPayment', params: { slug: 'hands-on' },}"
            class="explore_lms_links dark:text-[#D6895B] text-[#FB8036]"
          >
            <span
              class="iconify inline-block font-bold text-2xl mb-2"
              data-icon="fluent:payment-32-filled"
            ></span>
            <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
              Make
            </p>
            <p class="font-bold text-sm">payment</p>

          </router-link>
        </template>
        <router-link
          :to="{ name: 'BadgesPage' }"
          class="explore_lms_links dark:text-[#755AE2] text-[#755AE2]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="material-symbols:social-leaderboard"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Check out the 
          </p>
          <p class="font-bold text-sm">Badges</p>
        </router-link>
        <router-link
          :to="{ name: 'AllCertificatesPage' }"
          class="explore_lms_links dark:text-[#5ae287] text-[#5ae287]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="ph:certificate-fill"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Check out your 
          </p>
          <p class="font-bold text-sm">Certificates</p>
        </router-link>
        <router-link
          :to="{ name: 'PeigesIndex' }"
          class="explore_lms_links dark:text-[#3a69a5] text-[#3a69a5]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="bytesize:portfolio"
          ></span>
          <p class="text-sm font-semibold text-[#3a69a5] dark:text-[#3a69a5]">
            My 
          </p>
          <p class="font-bold text-sm">Portfolio</p>
        </router-link>
        <router-link
          :to="{ name: 'LiveClassesPage' }"
          v-if="showForInstructorLed"
          class="explore_lms_links dark:text-[#42A74E] text-[#20C933]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="subway:video"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Check out previous
          </p>
          <p class="font-bold text-sm">live classes</p>
        </router-link>
        <router-link
          :to="{ name: 'LeaderBoardPage' }"
          class="explore_lms_links dark:text-[#42A74E] text-[#20C933]"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="iconoir:leaderboard-star"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Go to the
          </p>
          <p class="font-bold text-sm">Leaderboard</p>
        </router-link>
        <router-link
          :to="{ name: 'ForumPage' }"
          v-if="showForInstructorLed"
          class="explore_lms_links dark:text-blue-500 text-blue-600"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="healthicons:forum-outline"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Forum
          </p>
          <p class="font-bold text-sm invisible">...</p>
        </router-link>
        <router-link
          :to="{ name: user?.hasTeam == 1 ? 'MyTeamPage' : 'SelectTeamPage' }"
          v-if="showForInstructorLed && user?.cohort < 10"
          class="explore_lms_links dark:text-blue-500 text-blue-600"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="game-icons:team-idea"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Team
          </p>
          <p class="font-bold text-sm">Challenge</p>
        </router-link>
        <router-link
          :to="{ name: 'LearnReferSpin' }"
          class="explore_lms_links dark:text-primary-green text-primary-green"
        >
          <span
            class="iconify inline-block font-bold text-2xl mb-2"
            data-icon="fa6-solid:users"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Refer and
          </p>
          <p class="font-bold text-sm">earn</p>
        </router-link>
        <router-link
          :to="{ name: 'LearningTimeTable' }"
          v-if="showForInstructorLed"
          class="explore_lms_links dark:text-[#5a46a7] text-[#5a46a7]"
        >
          <span
            class="iconify inline-block font-bold text-xl mb-2"
            data-icon="fa:list-ul"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Class
          </p>
          <p class="font-bold text-sm">schedule</p>
        </router-link>
        <div 
          @click="user.hasPaidBootcamp == true ? $router.push({name : 'BootcampWaitPage'}) : showBootcampModal = true" 
          v-if="settings.bootcamp_payment_active == 1 && user.cohort >= 7 && user.batch?.course.bootcamp_available == 1" 
          class="explore_lms_links"
        >
            <span
                class="iconify inline-block font-bold text-2xl mb-2"
                data-icon="emojione-monotone:b-button"
            ></span>
            <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Bootcamp
          </p>
          <p class="font-bold text-sm"> </p>
        </div>
        <div 
          @click="$router.push({name : 'PeigesIndex'})" 
          class="explore_lms_links"
        >
            <span
                class="iconify inline-block font-bold text-2xl mb-2"
                data-icon="bytesize:portfolio"
            ></span>
            <p class="text-sm font-semibold text-[#111111] dark:text-[#e3e3e3]">
            My
          </p>
          <p class="font-bold text-sm">Portfolio</p>
        </div>
        <button
          @click="$router.push({ name: 'UpgradeCourse'})"
          v-if="user?.cohort >= 8 && user?.batch?.learning_type == 'self_paced'"
          :disabled="user?.batch?.course?.is_available_instructor != 1 || user?.access_weeks < 2"
          class="explore_lms_links dark:text-[#5a46a7] text-[#5a46a7] disabled:cursor-not-allowed disabled:opacity-[75%] relative"
        >
          <button v-if="user?.batch?.course?.is_available_instructor != 1 || user?.access_weeks < 2" class="text-gray-500 text-sm absolute top-4 right-4">
            <Tooltip v-if="user?.batch?.course?.is_available_instructor != 1">
              <template #button>
                <i class="fa-solid fa-lock"></i>
              </template>
              <template #message>
                The instructor-led option is not available for this course yet.
              </template>
            </Tooltip>
            <Tooltip v-else>
              <template #button>
                <i class="fa-solid fa-lock"></i>
              </template>
              <template #message>
                Complete your payment for this course to unlock the upgrade feature.
              </template>
            </Tooltip>
          </button>
          <span
            class="iconify inline-block font-bold text-xl mb-2"
            data-icon="carbon:intent-request-upgrade"
          ></span>
          <p class="text-sm font-semibold text-[#384138] dark:text-[#e3e3e3]">
            Upgrade to
          </p>
          <p class="font-bold text-sm">instructor led</p>
        </button>
      </nav>
    </div>
  </div>
  <!-- End Navigation Buttons -->
</template>

<script>
import BootcampModal from "@/components/lms/BootcampNotice.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { mapGetters } from "vuex";
import Tooltip from "@/components/lms/TooltipComponent.vue"

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      userCourse: "auth/userCourse",
      settings: "auth/settings",
    }),
    showForInstructorLed(){
      const user = this.user;
      if(
            (user?.cohort >= 8 && user?.batch?.learning_type == 'instructor') 
              || 
            (user?.cohort >= 10 && user?.batch?.course?.is_available_instructor == 1)
        ) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      //
      showBootcampModal: false,
    };
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    BootcampModal,
    Tooltip,
  },
  methods: {
    //
  },
  mounted() {
    // 
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}
.explore_lms_links {
  @apply h-full flex flex-col w-full space-y-1 bg-[#FFFFFF] dark:bg-[#333] transition ease-linear duration-150 hover:md:shadow-[0px_6.64384px_25.774px_rgba(0,0,0,0.20)] shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-md p-4 cursor-pointer;
}

.explore_lms_links:hover {
  @apply md:-translate-y-2;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.11);
  }
  100% {
    transform: scale(1);
  }
}
</style>
