<template>
  <div
    class="fixed z-[100] bottom-0 right-0 p-6 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:bg-[#1A1A1A] dark:text-[#CBCDCB]"
    role="alert"
    v-if="!skip"
  >
    <div class="flex">
      <div
        class="p-3 mr-4 flex items-center justify-center rounded-full dark:bg-[#52A35038] bg-[#EDF7F1] h-8 w-8"
      >
        <i
          class="fa-regular fa-bell dark:text-[#52A350] text-primary-green text-lg"
        ></i>
        <span class="sr-only">Refresh icon</span>
      </div>
      <div class="ml-3 text-lg font-normal">
        <span class="mb-1 text-lg font-semibold text-gray-900 dark:text-white"
          >Allow Notification</span
        >
        <div class="mb-6 text-sm font-normal">
          Allow notifcation to recieve update from our site
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div>
            <button
              @click="askForPermission"
              class="inline-flex justify-center w-full p-3 text-xs font-medium text-center text-white bg-primary-green rounded-lg hover:bg-bg-primary-green focus:ring-1 focus:outline-none focus:ring-bg-primary-green dark:bg-primary-green dark:hover:bg-primary-green dark:focus:ring-primary-green"
            >
              Allow
            </button>
          </div>
          <div>
            <button
              @click="skipNotification"
              class="inline-flex justify-center w-full p-3 text-xs font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
            >
              Not now
            </button>
          </div>
        </div>
      </div>
      <span
        class="bg-transparent absolute right-3 top-2 cursor-pointer hover:text-secondary-green"
        aria-label="Close"
        @click="skipNotification"
      >
        <i class="fa-solid fa-times dark:text-grey"></i>
      </span>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { useStore } from "vuex";
import { inject, onMounted, ref } from "vue";
import { computed } from "@vue/reactivity";
import swal from "sweetalert";

const axios = inject("axios");

const store = useStore();
const user = computed(() => {
  return store.state.auth.user;
});

const dueForUpdate = computed(() => {
  let today = moment();
  let lastUpdate = moment(user.value?.device_token?.updated_at);
  return lastUpdate.diff(today, "days", true) > 29 ? true : false;
});
const attempt = () => store.dispatch("auth/attempt");

let skip = ref(false);

/**
 * Skip alert for later
 */
const skipNotification = () => {
  skip.value = true;
};

</script>

<style lang="scss" scoped></style>
