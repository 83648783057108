<template>
  <NavBar />
  <div class="container py-16">
    <div class="md:w-3/5 mx-auto py-14 space-y-8">
      <img
        v-if="badge?.file"
        :src="badge?.file"
        alt="Side Hustle Badge"
        class="outline outline-1 outline-primary-green max-h-96 text-center mx-auto"
      />
      <details class="md:w-3/5 mx-auto font-body" v-if="badge?.file">
        <summary
          class="text-[#8D998C] font-medium text-center md:text-left mb-2 cursor-pointer"
        >
          <span class="iconify inline" data-icon="bx:share"></span>
          Share
        </summary>
        <div
          class="grid grid-flow-col grid-rows-2 md:grid-rows-1 justify-center gap-4 font-medium"
        >
          <a
            class="twitter-share-button rounded-md px-3 py-2 bg-[#1DA1F2] text-white w-fit whitespace-nowrap text-xs"
            target="_blank"
            rel="noopener noreferrer"
            data-size="large"
            :href="`https://twitter.com/intent/tweet?text=Check out my Side Hustle Internship Badge ${location}`"
          >
            <i class="fa-brands fa-twitter"></i> Tweet
          </a>
          <a
            class="rounded-md px-3 py-2 bg-[#0077B5] text-white w-fit whitespace-nowrap text-xs"
            :href="`https://www.linkedin.com/sharing/share-offsite/?url=${location}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa-brands fa-linkedin-in"></i> LinkedIn
          </a>
          <a
            class="rounded-md px-3 py-2 bg-[#4267B2] text-white w-fit whitespace-nowrap text-xs"
            :href="`https://www.facebook.com/sharer/sharer.php?u=${location}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa-brands fa-facebook-f"></i> Facebook
          </a>
          <a
            class="rounded-md px-3 py-2 bg-[#25D366] text-white w-fit whitespace-nowrap text-xs"
            :href="
              'whatsapp://send?text=Check out my Side Hustle Internship Badge ' +
              location
            "
            data-action="share/whatsapp/share"
          >
            <i class="fa-brands fa-whatsapp"></i> Whatsapp</a
          >
        </div>
      </details>
    </div>
  </div>
</template>

<script setup>
import NavBar from "@/components/inc/PrimaryNavBar.vue";
import { ref } from "@vue/reactivity";
import { inject, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
const axios = inject("axios");
const $loading = inject("$loading");

const location = window.location.href; // url of current page
const router = useRouter();
const route = useRoute();
const badge = ref(null);

/**
 * Fetch Badge Flier Image
 * @param int -- Awarded Badge ID
 * @return { Object }
 */
const fetchBadge = async (id) => {
  let loader = $loading.show();
  try {
    const payload = {
      id: id,
    };
    const { data } = await axios.post("badge/share", payload);
    badge.value = data.payload;
    loader.hide();
    // if no file is return in response -- router.push() to home page
    if (!data.payload.file) {
      return router.push("/");
    }
  } catch (error) {
    console.log(error);
    loader.hide();
    router.push("/");
  }
};

onMounted(() => {
  if (!route?.params?.id) {
    router.push("/");
  }
  try {
    const params = route?.params?.id?.split("_");
    const badgeID = params[0];
    if (badgeID) {
      fetchBadge(badgeID);
    } else {
      router.push("/");
    }
  } catch (error) {
    return console.log(error);
    router.push("/");
  }
});
</script>

<style></style>
