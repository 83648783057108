<template>
  <div>
    <div class="container md:p-0">
      <BackButton class="my-4 md:my-12" />
      <!-- User Avatar & Progress -->
      <div class="my-8 md:gap-8 lg:w-4/6 flex flex-col">
        <div class="flex items-center space-x-4">
          <div
            class="font-body w-fit mx-auto text-primary-green mt-8 md:mt-0 whitespace-nowrap cursor-pointer flex items-center space-x-4"
            v-if="true"
            @click="shareRank()"
          >
            <span
              class="iconify inline"
              data-icon="clarity:share-solid"
            ></span>
          </div>
          <StreakBar />
        </div>
        <div
          class="h-24 w-24 rounded-2xl mx-auto grid place-content-center relative"
          :class="user.avatar ? '' : 'bg-[#F2F2F2]'"
        >
          <img
            :src="user.avatar + '?' + Date.now()"
            alt=""
            v-if="user.avatar"
            class="h-full w-full rounded-xl"
          />
          <span class="font-body text-3xl font-medium block" v-else>SH</span>
          <span
            class="font-body text-xs cursor-pointer font-semibold text-primary-green absolute right-6 bottom-1"
            @click="changeAvatar"
            >Change</span
          >
        </div>

        <div
          class="flex flex-col md:flex-row ml-4 md:ml-0 md:items-center grow justify-between"
        >
          <!-- <StreakBar /> -->
          <div
            class="font-body w-fit mx-auto text-primary-green mt-8 md:mt-0 whitespace-nowrap cursor-pointer flex items-center space-x-4"
            v-if="true"
            @click="shareProfile"
          >
            <span
              class="iconify inline lg:hidden"
              data-icon="clarity:share-solid"
            ></span>
            <span class="inline lg:hidden">Share Profile</span>
            <span class="hidden lg:inline-block"
              >Click here to download your Profile flyer</span
            >
          </div>
        </div>
      </div>
      <!-- User Avatar & Progress~ -->
      <!-- Update User Profile -->
      <div class="lg:w-4/6 my-16 font-body">
        <div
          class="my-8 border-b text-[#A9A9A9] dark:border-[#CECECE] border-[#00000026]"
        >
          <button
            @click="openSlot = 1"
            class="mr-4 -mb-1"
            :class="[
              openSlot == 1
                ? 'border-b-2 border-primary-green font-semibold text-primary-green'
                : '',
            ]"
          >
            Profile
          </button>
          <button
            @click="openSlot = 2"
            class="mr-4 -mb-1"
            v-if="false"
            :class="[
              openSlot == 2
                ? 'border-b-2 border-primary-green font-semibold text-primary-green'
                : '',
            ]"
          >
            Course
          </button>
        </div>
        <input
          type="file"
          ref="fileInput"
          class="hidden"
          @change="uploadPicture"
          accept=".jpg, .jpeg, .png"
        />
        <EditProfile v-if="openSlot == 1" />
        <Course v-if="openSlot == 2 && false" />
      </div>
      <!-- Update User Profile -->
    </div>
  </div>
</template>

<script setup>
import { share as shareService } from "@/helpers/navigatorShare.js";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import swal from "sweetalert";
import StreakBar from "@/components/lms/StreakBarComponent.vue";
import EditProfile from "./EditProfileComponent.vue";
import Course from "./CourseComponent.vue";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useRoute } from "vue-router";
const axios = inject("axios");
const $loading = inject("$loading");
const openSlot = ref(1);
const fileInput = ref(null);
const store = useStore();

const route = useRoute();

const user = computed(() => {
  return store.state.auth.user;
});

/**
 * Change profile picture - this trigger the hidden inout field
 */
const changeAvatar = async () => {
  fileInput.value.click();
};

/**
 * upload user selected picture
 */
const uploadPicture = async () => {
  const file = fileInput.value.files[0];
  if (file == undefined) return;
  const maxAllowedSize = 2 * 1024 * 1024;
  if (file.size > maxAllowedSize) {
    swal({
      title: "Oops!",
      text: "Max allowed file size is 2MB",
    });
    return;
  }
  const payload = new FormData();
  payload.append("file", file);
  const loader = $loading.show();
  try {
    await axios.post("auth/update/avatar", payload);
    await store.dispatch("auth/attempt", {})
    loader.hide();
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to update your profile at the moment. Please try again shortly.",
    });
  }
};

const shareProfile = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("auth/profile/share")
    if (!data.payload) {
      return;
    }
    await shareService(
      data.payload, // file
      `${user.value.first_name}_${user.value.last_name}_Profile`, // file_name
      `Hey, I am super excited to announce that I'm currently enrolled in Terra Learning's tech program`, //text
      `https://lms.terrahq.co/` // url
    );  
    loader.hide();    
  } catch (error) {
    console.error(`${error.name}: ${error.message}`);
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to share profile at the moment. Please try again shortly.",
    });
  }
};
const shareRank = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("auth/streaks/share")
    if (!data.payload) {
      return;
    }
    await shareService(
      data.payload, // file
      `${user.value.first_name}_${user.value.last_name}_rank`, // file_name
      `Hey, I am super excited to announce that I'm currently enrolled in Terra Learning's tech program`, //text
      `https://lms.terrahq.co/` // url
    );  
    loader.hide();
  } catch (error) {
    console.error(`${error.name}: ${error.message}`);
    loader.hide();
  }
};
onMounted(() => {
  if (route?.params?.slot) {
    openSlot.value = route?.params?.slot ?? 1;
  }
});
</script>

<style scoped>
label {
  @apply absolute -translate-y-1/2 left-4 text-sm px-3 transition-all ease-linear duration-300;
}
input {
  @apply w-full focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 text-sm font-body border rounded border-[#8B908B] focus:placeholder:text-transparent placeholder:text-base placeholder:text-[#858585];
}
button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF] text-[#707070];
}
</style>
