<template>
  <div>
    <div class="container px-0">
      <div class="grid lg:grid-cols-2 lg:gap-16">
        <div class="-ml-24">
          <img
            src="@/assets/img/img/dashboard-mockup.png"
            class="w-full h-auto"
            alt="Side Hustle Internship Learning Experience Platform"
            loading="lazy"
          />
        </div>
        <div class="flex items-center lg:pr-16 mx-4 lg:mx-0 lg:mt-24">
          <div class="text-center lg:text-left">
            <h2
              class="font-header-1 font-extrabold text-3xl md:text-5xl text-body-text lg:w-3/5"
            >
              <span class="text-primary-green">Learn</span> in-demand skills
              seamlessly
            </h2>
            <p
              class="my-4 text-sm font-normal text-center lg:text-left text-[#474747] font-body lg:w-4/5"
            >
              Imagine learning in an environment built to give you the best
              learning experience.
            </p>
            <p
              class="mb-8 text-sm font-normal text-center lg:text-left text-[#474747] font-body lg:w-4/5"
            >
              A blend of self-paced and instructor-led training with lots of
              hands-on experience to ensure you are well-rounded in the track of
              your choice.
            </p>
            <div class="lg:h-[400px]">
              <div
                class="font-body mb-16 border border-[#CACECA] rounded-md overflow-clip"
              >
                <Disclosure v-slot="{ open }">
                  <DisclosureButton
                    :class="
                      open
                        ? 'text-primary-green bg-[#EEF7EE]'
                        : 'bg-[#FAFAFA] text-[#2F2F2F]'
                    "
                    class="py-2 px-2 w-full flex justify-between items-center font-semibold border-b border-grey-300"
                  >
                    Perks
                    <i
                      class="fa-solid fa-angle-right transition-all ease-linear mr-4 md:mr-8"
                      :class="open ? 'transform rotate-90' : ''"
                    ></i>
                  </DisclosureButton>
                  <DisclosurePanel
                    class="text-[#3F473F] p-2 bg-[#FFFFFF] border-y border-[#CACECA]"
                  >
                    <ul
                      class="list-outside px-4 py-2 list-disc text-left text-sm space-y-3"
                    >
                      <li>
                        Earn a certificate of completion at the end of the
                        Internship
                      </li>
                      <li>
                        A chance to build your portfolio in the Terra Learning bootcamp
                      </li>
                      <li>
                        Boost your portfolio by taking part in team projects
                        and creating world class projects in the bootcamp
                      </li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>
              <router-link :to="{ name: 'RegisterEmail' }" class="">
                <PrimaryButton class="w-full md:w-fit">
                  Sign up for FREE

                </PrimaryButton>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
</script>
<script>
import PrimaryButton from "./../inc/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
};
</script>

<style></style>
