import LearningDashboardContainer from "@/views/lms/LearningDashboardContainer.vue";
import LearningDashboardIndex from "@/views/lms/LearningDashboardIndex.vue";
import StartWaitPage from "@/views/lms/StartWaitPage.vue"
import AssessmentHistory from "@/views/lms/history/AssessmentHistory.vue";
import ProfilePage from "@/views/lms/profile/ProfilePage.vue";
import PaymentPage from "@/views/lms/payment/PaymentPage.vue";
import BadgesPage from "@/views/lms/badges/BadgesPage.vue";
import ForumPage from "@/views/lms/forum/ForumPage.vue";
import LessonsContainer from "@/views/lms/lessons/LessonsContainer.vue";
import LessonPage from "@/views/lms/lessons/LessonPage.vue";
import QuizInstructionPage from "@/views/lms/lessons/quiz/QuizInstructionPage.vue";
import TaskInstructionPage from "@/views/lms/lessons/task/TaskInstructionPage.vue";
import ReviewTaskInstructionPage from "@/views/lms/lessons/task/ReviewTaskInstructionPage.vue";
import RetakeTaskInstructionPage from "@/views/lms/lessons/task/RetakeTaskInstructionPage.vue";
import ResourceInstructionPage from "@/views/lms/lessons/resource/ResourceInstructionPage.vue";
import TakeQuizPage from "@/views/lms/lessons/quiz/TakeQuizPage.vue";
import QuizPreviewPage from "@/views/lms/lessons/quiz/QuizPreviewPage.vue";
import ChoosePayment from "@/views/lms/payment/ChoosePayment.vue";

import CourseCertificatePage from "@/views/lms/lessons/CourseCertificatePage.vue";
// import AllCertificatesPage from "@/views/lms/AllCertificatesPage.vue";
import SelectBatchPage from "@/views/lms/SelectBatchPage.vue";

// LMS Jobberman
import LearningJobbermanEnroll from "@/views/lms/jobberman/JobbermanEnroll.vue";
import LearningJobbermanBaseline from "@/views/lms/jobberman/JobbermanBaseline.vue";
import LearningJobbermanQuiz from "@/views/lms/jobberman/JobbermanQuiz.vue";
import LearningJobbermanLessons from "@/views/lms/jobberman/JobbermanLessons.vue";

// LMS Raven
import LearningRavenVerify from "@/views/lms/raven/VerifyAccount.vue";
import LearningRaven from "@/views/lms/raven/Raven.vue";

// LMS TImetable
import LearningTimeTable from "@/views/lms/course-details/LearningTimeTable.vue";

/**
 * LMS Refer and Spin
 */
import LearnReferSpin from "@/views/lms/referral/ReferSpin.vue";

// View Shared Badge
import ViewBadgePage from "@/views/lms/badges/ViewBadgePage.vue";

// Bootcamp Selection
import AllTeamsPage from "@/views/lms/bootcamp/AllTeamsPage.vue";
import TeamDetails from "@/views/lms/bootcamp/TeamDetails.vue";
import UserTeamPage from "@/views/lms/bootcamp/UserTeamPage.vue";

// Team Challenge
import SelectTeamPage from "@/views/lms/teams/SelectTeamPage.vue";
import SelectTeamDetails from "@/views/lms/teams/SelectTeamDetails.vue";
import MyTeamPage from "@/views/lms/teams/MyTeamPage.vue";

// Re-application Process
import ReapplicationContainer from "@/views/apply/ApplicationContainer.vue";

import Checkout from "../views/lms/payment/Checkout.vue";
import CommitmentFeeCountdown from "../views/lms/payment/CommitmentFeeCountdown.vue";

// Live Classes
import LiveClassesPage from "@/views/lms/liveclasses/LiveClassesPage.vue";

import BaselineAssessmentContainer from "@/views/lms/baseline/BaselineAssessmentContainer.vue";
import AssessmentSubmitted from "@/views/lms/baseline/AssessmentSubmitted.vue";
import SelectPath from "@/views/lms/baseline/SelectPath.vue";
import CommitmentPayment from "@/views/lms/baseline/CommitmentPayment.vue";

//Game
import GameStart from "@/views/lms/game/GameStart.vue";
import GameStartTest from "@/views/lms/game/GameStartTest.vue";
import GameEnd from "@/views/lms/game/GameEnd.vue";
import GameScore from "@/views/lms/game/GameScore.vue";
import TrexPage from "@/views/lms/game/TrexPage.vue";


import LeaderBoardPage from "@/views/lms/leaderboard/LeaderBoardPage.vue"
import ViewWeeklyTasks from "@/views/lms/tasks/ViewWeeklyTasks.vue"
import AdditionalResources from "@/views/lms/tasks/AdditionalResources.vue"

import UpgradeCourse from "@/views/apply/UpgradeCourse.vue"

import store from "./../store";

const learningRoutes = [
  {
    path: "/dashboard/learn/",
    name: "LearningDashboardContainer",
    component: LearningDashboardContainer,
    meta: {
      requireAuth: true,
      passBaseline: true,
      hasBatchGuard: true,
      hasLearningGuard: true,
    },
    children: [
      {
        path: "",
        name: "LearningDashboardIndex",
        component: LearningDashboardIndex,
        meta: {
          title: "Learning Dashboard",
          sub_title: "Dashboard",
        },
      },
      {
        path: "wait",
        name: "StartWaitPage",
        component: StartWaitPage,
        meta: {
          title: "Dashboard",
          sub_title: "Dashboard",
          hasBatchGuard: false,
        },
      },
      {
        path: "profile",
        name: "ProfilePage",
        component: ProfilePage,
        meta: {
          title: "Profile",
          sub_title: "Profile",
        },
      },
      {
        path: "payment",
        component: PaymentPage,
        meta:{
          oldCohort: true,
        },
        children: [
          {
            path: "",
            name: "ChoosePayment",
            component: ChoosePayment,
            meta: {
              title: "Choose Payment Type",
              sub_title: "Payment",
            },
          },
        ],
      },
      {
        path: "badges",
        name: "BadgesPage",
        component: BadgesPage,
        meta: {
          title: "Badges",
          sub_title: "Badges",
          instructorGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "forum",
        name: "ForumPage",
        component: ForumPage,
        meta: {
          title: "Forum",
          sub_title: "Forum",
          instructorGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "history",
        name: "AssessmentHistory",
        component: AssessmentHistory,
        meta: {
          title: "Assessment History",
          sub_title: "Assessment History",
        },
      },
      {
        path: "lessons",
        component: LessonsContainer,
        meta: {
          title: "Lessons",
          breadcrumb: "Lessons",
          sub_title: "Lessons",
        },
        children: [
          {
            path: "",
            name: "LessonPage",
            component: LessonPage,
            params: true,
            meta: {
              title: "Lessons",
              breadcrumb: "Lesson",
              sub_title: "Lesson",
            },
          },
          {
            path: "quiz/review/:id",
            name: "QuizPreviewPage",
            component: QuizPreviewPage,
            meta: {
              breadcrumb: "Quiz",
              title: "Review Quiz",
              sub_title: "Quiz",
            },
          },
          {
            path: "quiz/:week",
            name: "QuizInstructionPage",
            component: QuizInstructionPage,
            meta: {
              breadcrumb: "Quiz",
              title: "Quiz Instructions",
              sub_title: "Quiz",
            },
          },
          {
            path:"task/:week",
            name: "TaskInstructionPage",
            component: TaskInstructionPage,
            meta: {
              breadcrumb: "Task",
              title: "Task",
              sub_title: "Task",
              instructorGuard: true,
              desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
            }
          },
          {
            path:"resource/:week",
            name: "ResourceInstructionPage",
            component: ResourceInstructionPage,
            meta: {
              breadcrumb: "Resource",
              title: "Resource",
              sub_title: "Resource"
            }
          },
          {
            path: "/dashboard/learn/certificate/:course",
            name: "CourseCertificatePage",
            component: CourseCertificatePage,
            meta: {
              breadcrumb: "Certificate",
              title: "Certificate",
              sub_title: "Certificate",
            },
          },
        ],
      },
      {
        path: "/dashboard/learn/upgrade",
        name: "UpgradeCourse",
        component: UpgradeCourse,
        meta: {
          breadcrumb: "Upgrade Learning",
          title: "Upgrade Learning",
          sub_title: "Upgrade Learning",
        },
      },
      {
        path: "/dashboard/learn/jobberman/enroll",
        name: "LearningJobbermanEnroll",
        component: LearningJobbermanEnroll,
        meta: {
          title: "Enroll for the Jobberman Soft Skills Training",
          sub_title: "Soft Skills",
        },
      },
      {
        path: "/dashboard/learn/jobberman/instruction",
        name: "LearningJobbermanBaseline",
        component: LearningJobbermanBaseline,
        meta: {
          title: "Read the following instructions carefully",
          sub_title: "Soft Skills",
        },
      },
      {
        path: "/dashboard/learn/jobberman/test",
        name: "LearningJobbermanQuiz",
        component: LearningJobbermanQuiz,
        meta: {
          title: "Take the Jobberman Assessment Test",
          sub_title: "Soft Skills",
        },
      },
      {
        path: "/dashboard/learn/jobberman/lessons",
        name: "LearningJobbermanLessons",
        component: LearningJobbermanLessons,
        meta: {
          title: "Watch your Soft Skills Lessons",
          sub_title: "Soft Skills",
        },
      },
      {
        path: "/dashboard/learn/raven",
        name: "LearningRaven",
        component: LearningRaven,
        meta: {
          title: "Raven Bank",
          sub_title: "Raven",
        },
      },
      {
        path: "/dashboard/learn/raven",
        name: "LearningRavenVerify",
        component: LearningRavenVerify,
        meta: {
          title: "Raven Bank",
          sub_title: "Raven",
        },
      },
      {
        path: "/dashboard/learn/schedule",
        name: "LearningTimeTable",
        component: LearningTimeTable,
        meta: {
          title: "Timetable",
          sub_title: "Timetable",
          instructorGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      /**
       * Refer and Spin page
       */
      {
        path: "referral",
        name: "LearnReferSpin",
        component: LearnReferSpin,
        meta: {
          title: "Refer and Spin",
          sub_title: "Referrals",
        },
      },
      {
        path: "/dashboard/teams",
        name: "SelectTeamPage",
        component: SelectTeamPage,
        meta: {
          title: "Team Challenge Selection",
          sub_title: "Challenge",
          hasTeamGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "/dashboard/team/:id",
        name: "SelectTeamDetails",
        component: SelectTeamDetails,
        meta: {
          title: "Team Challenge Selection",
          sub_title: "Challenge",
          hasTeamGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "/dashboard/team",
        name: "MyTeamPage",
        component: MyTeamPage,
        meta: {
          title: "Team Challenge Selection",
          sub_title: "Challenge",
          hasTeamGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "/dashboard/bootcamp/teams",
        name: "AllTeamsPage",
        component: AllTeamsPage,
        meta: {
          title: "Bootcamp Team Selection",
          sub_title: "Bootcamp",
        },
      },
      {
        path: "/dashboard/bootcamp/team",
        name: "TeamDetails",
        component: TeamDetails,
        meta: {
          title: "Bootcamp Team Selection",
          sub_title: "Bootcamp",
        },
      },
      {
        path: "/dashboard/bootcamp/my-team",
        name: "UserTeamPage",
        component: UserTeamPage,
        meta: {
          title: "Bootcamp Team",
          sub_title: "Bootcamp",
        },
      },
      {
        path: "/dashboard/classes/recording",
        name: "LiveClassesPage",
        component: LiveClassesPage,
        meta: {
          title: "Links to Recording of Live Classes",
          sub_title: "Live Classes",
          instructorGuard: true,
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "/dashboard/leaderboard",
        name: "LeaderBoardPage",
        component: LeaderBoardPage,
        meta: {
          title: "Leaderboard",
          sub_title: "Leaderboard",
          desc: "This feature is available to only Instructor-led users. To have access, contact the support team at accounts@sidehustle.ng to upgrade to Instructor-Led Mode"
        },
      },
      {
        path: "/dashboard/game",
        name: "GameStart",
        component: GameStart,
        meta: {
          title: "GameStart",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/games",
        name: "GameStartTest",
        component: GameStartTest,
        meta: {
          title: "GameStartTest",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/game/end",
        name: "GameEnd",
        component: GameEnd,
        meta: {
          title: "GameEnd",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/game/score",
        name: "GameScore",
        component: GameScore,
        meta: {
          title: "GameScore",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/game/trex",
        name: "TrexPage",
        component: TrexPage,
        meta: {
          title: "TrexPage",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/learn/tasks/:week",
        name: "ViewWeeklyTasks",
        component: ViewWeeklyTasks,
        meta: {
          title: "Assessment Task",
          requireAuth: true,
        },
      },
      {
        path: "/dashboard/learn/resource/:week",
        name: "AdditionalResources",
        component: AdditionalResources,
        meta: {
          title: "Resources",
          requireAuth: true,
        }
      },
      {
        path: "/dashboard/learn/review/:week",
        name: "ReviewTaskInstructionPage",
        component: ReviewTaskInstructionPage,
        meta: {
          breadcrumb: "Review",
          title: "Review",
          sub_title: "Review",
        },
      },
      {
        path: "/dashboard/learn/retake/:week",
        name: "RetakeTaskInstructionPage",
        component: RetakeTaskInstructionPage,
        meta: {
          breadcrumb: "Retake",
          title: "Retake",
          sub_title: "Retake",
        },
      },
    ],
  },
  {
    name: "ReapplicationContainer",
    component: ReapplicationContainer,
    path: "/dashboard/learn/",
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "welcome",
        name: "SelectBatchPage",
        component: SelectBatchPage,
        meta: {
          title: "Learning Dashboard",
          sub_title: "Dashboard",
        },
      },
      {
        path: "select/learningpath",
        name: "SelectPath",
        component: SelectPath,
        meta: {
          title: "Select Learning Path",
        },
      },
      {
        path: "pay/commitment",
        name: "CommitmentPayment",
        component: CommitmentPayment,
        meta: {
          title: "Pay Commitment Fee",
        },
      },
      {
        path: "pay/countdown",
        name: "CommitmentFeeCountdown",
        component: CommitmentFeeCountdown,
        meta: {
          title: "Kindly, Wait while we confirm your payment",
        },
      },
    ],
  },

  {
    path: "/dashboard/payment/:id",
    name: "Checkout",
    component: Checkout,
    meta: {
      title: "Payment",
      requireAuth: true,
      oldCohort: true,
    },
  },
  {
    path: "/dashboard/learn/quiz/re-take/:week",
    name: "ReTakeQuizPage",
    component: TakeQuizPage,
    meta: {
      breadcrumb: "Re-Take Quiz",
      title: "Re-Take Quiz",
      sub_title: "Quiz",
      requireAuth: true,
    },
  },
  {
    path: "/dashboard/learn/quiz/take/:week",
    name: "TakeQuizPage",
    component: TakeQuizPage,
    meta: {
      requireAuth: true,
      breadcrumb: "Take Quiz",
      title: "Take Quiz",
      sub_title: "Quiz",
    },
  },
  {
    path: "/badge/:id",
    name: "ViewBadgePage",
    component: ViewBadgePage,
    meta: {
      title: "Side Hustle Internship Badge",
      sub_title: "Badge",
    },
  },
];

export default learningRoutes;
