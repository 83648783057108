<template>
  <div
    class="fixed bg-[#636363]/[0.27] overflow-y-scroll scrollbar-hide backdrop-blur-[10px] custom_fade top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center z-[10000]"
  >
    <div
      class="container p-0 flex items-center justify-center custom_bounce h-full"
    >
      <div
        class="max-w-[90vw] max-h-[95vh] font-body md:w-fit text-[#1A1F1A] lg:w-fit lg:px-10 pt-4 pb-10 px-7 bg-[#FCFDFC] rounded-2xl flex flex-col justify-between items-center text-center space-y-6 relative overflow-y-scroll scrollbar-hide"
      >
        <div
          @click="$emit('closeBootcampNotice')"
          class="flex cursor-pointer items-center py-2 pr-5 text-primary-green mr-auto hidden"
        >
          <span
            class="iconify text-primary-green bg-[#E4F2E4] rounded-full mr-2"
            data-icon="ion:close"
          ></span>
          <p class="font-body text-sm">CLOSE</p>
        </div>
        <h1 class="text-[#3B433B] text-xl font-bold md:text-3xl mt-9">
          Registration for the 2024 Terra Bootcamp
        </h1>
        <p>
            The bootcamp starts in 
            <Vue-countdown
                class="font-semibold text-black ml-1"
                :time="convertTime(bootcamp_start_date)"
                v-slot="{ days, hours, minutes, seconds }"
                >{{days}} day{{days > 1 ? 's' : ''}} , {{hours}} hour{{hours > 1 ? 's' : ''}} {{ minutes }} minute{{minutes > 1 ? 's' : ''}} {{seconds}} second{{seconds > 1 ? 's' : ''}}
            </Vue-countdown>
        </p>
        <PrimaryButton
          @click="
            $router.push({ name: 'ApplicationPayment', params: { slug: 'bootcamp' }, }),
              $emit('closeBootcampNotice')
          "
          >Register for bootcamp</PrimaryButton
        >
        <button
          @click="$emit('closeBootcampNotice')"
          class="hover:border-hover-green hover:text-hover-green transition ease-linear rounded-md px-3 py-1.5"
        >
          I'll pay later
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryButton from "../../components/inc/SecondaryButton.vue";
import PrimaryButton from "../inc/PrimaryButton.vue";
import { mapGetters } from "vuex";
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  data() {
    return {
        bootcamp_start_date: "Mon September 16 2024 00:01:06 GMT+0100 (West Africa Standard Time)"
    };
  },
  components: {
    SecondaryButton,
    PrimaryButton,
    VueCountdown
  },
  methods: {
    convertTime(time) {
        const now = new Date().getTime();
        var date = new Date(time);
        date = new Date(date.getTime() + 60*60*1000)
        return date - now;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
